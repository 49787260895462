@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Amatic+SC:wght@700&family=Dancing+Script:wght@400;500;600;700&family=Puppies+Play&family=Ruge+Boogie&display=swap");
@import 'photoswipe/dist/photoswipe.css';
@import url("https://use.typekit.net/ido6zju.css");

:root {
  --color: rgb(176, 21, 71);
  --color-nav: rgba(176, 21, 71, 0.8);
  --color-brown: rgb(133, 96, 105);
  --background-color: rgba(245, 245, 245, 0.4);
  --font-family: "Amarillo", sans-serif;
  --bs-body-color-rgb: 133, 96, 105;
  --border: 0.2rem solid var(--color);
  --border-hover: 0.2rem solid var(--color-brown);
  --border-radius: 50% 20% / 10% 40%;
  --border-radius-hover: 20% 50% / 40% 10%;
  --border-bottom: 4px var(--color) solid;
  --font-family-poppins: "Poppins", sans-serif;
  --font-family-amatic: "Amatic SC", cursive;
  --font-family-dancing: "Dancing Script", cursive;
  --font-family-puppies: "Puppies Play", cursive;
  --font-family-ruge: "Ruge Boogie", cursive;
}


.home-logo {
  animation: imageAnimation 4s linear infinite;
}

@keyframes imageAnimation {

  0%,
  100% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(0.8);
  }
}

.footer-link:hover {
  color: var(--color);
  background-color: white;
  border: var(--border-hover);
  border-radius: var(--border-radius-hover);
  padding: 5px;
}

strong:hover {
  border-bottom: var(--color) 2px solid;
}

.small-images {
  box-sizing: border-box;
  height: fit-content;
  width: 120px;
}

.page-title:after {
  content: '';
  width: 7%;
  margin: 0 auto;
  height: 1px;
  display: block;
  clear: both;
  margin-top: 0.233em;
  background-color: var(--color);
}

.form-select,
option {
  font-family: var(--font-family-amatic);
  font-size: 1.5rem;
  width: 100%;
}


#gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 100%;
  width: 100%;
}

.home-content {
  color: white;
  transition: transform ease 300ms;
  background-color: var(--color-brown);
}

.home-content:hover {
  transform: translate(0, -40px);
  background-color: var(--color);
}

.home-img:hover {
  border-radius: 50% 20% / 10% 40%

}

// @media only screen and (min-width: 900px) {
//   .nav:hover {
//     height: 230px;
//     transform: translate(200, 230px);
//   }

// }